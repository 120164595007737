import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";

function ServicesOffered() {
  const services = [
    { title: "Roofing", link: "roofing" },
    { title: "Windows and Doors", link: "windows-doors" }, 
    { title: "Siding", link: "siding" },  
  ];
  return (
    <div>
      <div>
        <div className="lg:mt-0 mt-6">
        <h4 className="text-base mb-4 leading-5 font-bold tracking-wider text-brand-700 uppercase font-display">Services</h4>
         
                  
          <ul className="mt-2 leading-relaxed p-0  list-none space-y-2">
            {services.map((service, i) => {
              const { title, link } = service;
              return (
                <li key={i} className=" hover:text-black font-display pb-1  leading-6 font-medium text-neutral-600">
                  

                  <Link to={`/${link}/`} title={`${title} Michigan`}>
                    {title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ServicesOffered;
