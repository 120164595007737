import React from "react";
import styled from "styled-components";

const Logo = ({ classes, singleColor }) => (


 <svg viewBox="0.00 0.00 600.00 231.00"  xmlns="http://www.w3.org/2000/svg" className={`${classes ? classes : "text-brand-500"} -my-3`}>
 
 
 <g>
 <path fill={`${singleColor ? 'currentColor' : '#d31436'}`} d="
   M 334.0176 103.8276
   A 27.43 27.43 0.0 0 1 306.6355 131.3054
   L 301.4155 131.3145
   A 27.43 27.43 0.0 0 1 273.9377 103.9324
   L 273.9224 95.2124
   A 27.43 27.43 0.0 0 1 301.3045 67.7346
   L 306.5245 67.7255
   A 27.43 27.43 0.0 0 1 334.0023 95.1076
   L 334.0176 103.8276
   Z
   M 302.12 82.86
   C 292.26 84.20 292.13 96.25 292.89 104.27
   Q 293.30 108.55 295.22 111.78
   C 298.49 117.30 308.12 117.74 312.08 112.79
   C 315.48 108.56 315.64 101.08 315.12 95.26
   C 314.42 87.32 310.85 81.67 302.12 82.86
   Z"
 />
 <path fill={`${singleColor ? 'currentColor' : '#d31436'}`} d="
   M 424.14 114.41
   Q 427.69 117.01 432.38 116.13
   Q 439.05 114.89 440.08 106.84
   Q 440.15 106.26 440.74 106.26
   L 457.31 106.25
   Q 458.01 106.25 457.96 106.95
   C 456.48 126.26 440.79 133.99 423.24 130.98
   C 410.10 128.73 402.04 118.99 400.33 105.96
   C 398.28 90.49 402.99 74.54 418.77 69.12
   C 425.67 66.75 434.97 66.90 441.84 69.40
   Q 456.26 74.65 457.71 91.84
   A 0.32 0.31 -2.5 0 1 457.39 92.18
   L 440.27 92.18
   A 0.59 0.59 0.0 0 1 439.68 91.66
   Q 438.76 83.31 430.75 82.76
   C 424.09 82.30 420.45 86.70 419.46 92.75
   C 418.35 99.49 418.15 110.04 424.14 114.41
   Z"
 />
 <path fill={`${singleColor ? 'currentColor' : '#d31436'}`} d="
   M 258.20 101.15
   A 0.39 0.39 0.0 0 0 258.26 101.88
   Q 266.05 104.09 266.60 112.56
   Q 266.98 118.46 267.16 124.57
   C 267.20 126.20 268.12 127.05 268.86 128.35
   A 0.96 0.96 0.0 0 1 268.02 129.78
   L 250.20 129.78
   A 0.68 0.68 0.0 0 1 249.53 129.21
   Q 248.44 122.77 248.72 116.66
   Q 249.07 109.33 241.84 108.92
   Q 237.47 108.67 233.48 108.81
   Q 232.79 108.84 232.79 109.53
   L 232.86 129.42
   A 0.36 0.36 0.0 0 1 232.50 129.78
   L 214.68 129.78
   A 0.36 0.35 0.0 0 1 214.32 129.43
   L 214.32 69.52
   Q 214.32 69.08 214.77 69.07
   Q 230.25 69.00 245.76 69.14
   C 254.05 69.22 263.50 71.40 265.92 80.55
   C 267.99 88.41 266.33 97.35 258.20 101.15
   Z
   M 232.77 84.22
   L 232.83 94.92
   A 0.33 0.33 0.0 0 0 233.16 95.25
   L 243.56 95.20
   A 5.71 4.95 -0.3 0 0 249.24 90.22
   L 249.24 88.76
   A 5.71 4.95 -0.3 0 0 243.50 83.84
   L 233.10 83.89
   A 0.33 0.33 0.0 0 0 232.77 84.22
   Z"
 />
 <path fill={`${singleColor ? 'currentColor' : '#d31436'}`} d="
   M 355.39 129.78
   L 337.55 129.78
   A 0.33 0.33 0.0 0 1 337.24 129.34
   L 357.55 69.28
   A 0.33 0.33 0.0 0 1 357.87 69.06
   L 376.40 69.05
   A 0.33 0.33 0.0 0 1 376.72 69.27
   L 396.99 129.35
   A 0.33 0.33 0.0 0 1 396.68 129.79
   L 378.72 129.78
   A 0.33 0.33 0.0 0 1 378.41 129.53
   L 376.42 122.05
   A 0.33 0.33 0.0 0 0 376.11 121.80
   L 358.09 121.81
   A 0.33 0.33 0.0 0 0 357.77 122.05
   L 355.71 129.54
   A 0.33 0.33 0.0 0 1 355.39 129.78
   Z
   M 361.41 108.54
   A 0.31 0.31 0.0 0 0 361.71 108.94
   L 372.51 108.96
   A 0.31 0.31 0.0 0 0 372.81 108.56
   L 367.44 89.06
   A 0.31 0.31 0.0 0 0 366.84 89.06
   L 361.41 108.54
   Z"
 />
 <path fill={`${singleColor ? 'currentColor' : '#d31436'}`} d="
   M 501.99 89.23
   L 501.96 69.43
   A 0.37 0.37 0.0 0 1 502.33 69.06
   L 520.43 69.05
   A 0.37 0.37 0.0 0 1 520.80 69.42
   L 520.81 129.41
   A 0.37 0.37 0.0 0 1 520.44 129.78
   L 502.33 129.78
   A 0.37 0.37 0.0 0 1 501.96 129.41
   L 501.99 106.62
   A 0.37 0.37 0.0 0 0 501.62 106.25
   L 485.00 106.25
   A 0.37 0.37 0.0 0 0 484.63 106.62
   L 484.67 129.41
   A 0.37 0.37 0.0 0 1 484.30 129.78
   L 466.23 129.78
   A 0.37 0.37 0.0 0 1 465.86 129.41
   L 465.87 69.43
   A 0.37 0.37 0.0 0 1 466.24 69.06
   L 484.30 69.05
   A 0.37 0.37 0.0 0 1 484.67 69.42
   L 484.62 89.24
   A 0.37 0.37 0.0 0 0 484.99 89.61
   L 501.62 89.60
   A 0.37 0.37 0.0 0 0 501.99 89.23
   Z"
 />
 <path fill={`${singleColor ? 'currentColor' : '#d31436'}`} d="
   M 164.14 90.00
   L 138.89 109.17
   A 0.30 0.30 0.0 0 1 138.71 109.23
   L 132.60 109.38
   A 0.30 0.30 0.0 0 1 132.41 108.84
   L 164.15 84.84
   A 0.30 0.30 0.0 0 1 164.51 84.84
   L 196.23 108.85
   A 0.30 0.30 0.0 0 1 196.04 109.39
   L 189.94 109.23
   A 0.30 0.30 0.0 0 1 189.76 109.17
   L 164.50 90.00
   A 0.30 0.30 0.0 0 0 164.14 90.00
   Z"
 />
 <path fill={`${singleColor ? 'currentColor' : '#d31436'}`} d="
   M 163.9322 101.6258
   A 0.39 0.39 0.0 0 1 163.5449 102.0185
   L 160.2650 102.0414
   A 0.39 0.39 0.0 0 1 159.8723 101.6541
   L 159.8478 98.1542
   A 0.39 0.39 0.0 0 1 160.2351 97.7615
   L 163.5150 97.7386
   A 0.39 0.39 0.0 0 1 163.9077 98.1259
   L 163.9322 101.6258
   Z"
 />
 <path fill={`${singleColor ? 'currentColor' : '#d31436'}`} d="
   M 169.0851 101.6999
   A 0.29 0.29 0.0 0 1 168.8008 101.9954
   L 165.2414 102.0638
   A 0.29 0.29 0.0 0 1 164.9459 101.7794
   L 164.8749 98.0801
   A 0.29 0.29 0.0 0 1 165.1592 97.7846
   L 168.7186 97.7162
   A 0.29 0.29 0.0 0 1 169.0141 98.0006
   L 169.0851 101.6999
   Z"
 />
 <path fill={`${singleColor ? 'currentColor' : '#d31436'}`} d="
   M 164.0682 106.9385
   A 0.52 0.52 0.0 0 1 163.5611 107.4710
   L 160.4220 107.5477
   A 0.52 0.52 0.0 0 1 159.8895 107.0406
   L 159.8118 103.8615
   A 0.52 0.52 0.0 0 1 160.3189 103.3290
   L 163.4580 103.2523
   A 0.52 0.52 0.0 0 1 163.9905 103.7594
   L 164.0682 106.9385
   Z"
 />
 <path fill={`${singleColor ? 'currentColor' : '#d31436'}`} d="
   M 169.0974 107.0580
   A 0.43 0.43 0.0 0 1 168.6719 107.4925
   L 165.3521 107.5273
   A 0.43 0.43 0.0 0 1 164.9176 107.1018
   L 164.8826 103.7620
   A 0.43 0.43 0.0 0 1 165.3081 103.3275
   L 168.6279 103.2927
   A 0.43 0.43 0.0 0 1 169.0624 103.7182
   L 169.0974 107.0580
   Z"
 />
 <path fill="currentColor" d="
   M 142.46 138.12
   L 142.48 160.82
   A 0.38 0.38 0.0 0 1 142.10 161.20
   L 132.35 161.19
   A 0.38 0.38 0.0 0 1 131.97 160.81
   L 131.97 114.70
   A 0.38 0.38 0.0 0 1 132.35 114.32
   L 142.13 114.30
   A 0.38 0.38 0.0 0 1 142.51 114.68
   L 142.43 128.37
   A 0.38 0.38 0.0 0 0 142.81 128.75
   L 196.86 128.75
   A 0.38 0.38 0.0 0 1 197.24 129.13
   L 197.24 160.82
   A 0.38 0.38 0.0 0 1 196.86 161.20
   L 186.96 161.20
   A 0.38 0.38 0.0 0 1 186.58 160.82
   L 186.60 138.12
   A 0.38 0.38 0.0 0 0 186.22 137.74
   L 142.84 137.74
   A 0.38 0.38 0.0 0 0 142.46 138.12
   Z"
 />
 <path fill="currentColor" d="
   M 197.01 123.26
   A 0.48 0.48 0.0 0 1 196.53 123.74
   L 186.97 123.74
   A 0.48 0.48 0.0 0 1 186.49 123.26
   L 186.49 114.70
   A 0.48 0.48 0.0 0 1 186.97 114.22
   L 196.53 114.22
   A 0.48 0.48 0.0 0 1 197.01 114.70
   L 197.01 123.26
   Z"
 />
 <path fill={`${singleColor ? 'currentColor' : '#d31436'}`} d="
   M 84.42 138.28
   L 84.45 160.73
   A 0.43 0.43 0.0 0 1 84.02 161.17
   L 74.36 161.16
   A 0.36 0.35 90.0 0 1 74.01 160.80
   Q 74.04 148.10 74.05 133.84
   C 74.05 130.63 76.35 128.76 79.35 128.76
   Q 102.09 128.74 126.46 128.76
   Q 126.75 128.76 126.75 129.04
   L 126.80 137.46
   Q 126.80 137.73 126.54 137.73
   L 84.97 137.73
   Q 84.42 137.73 84.42 138.28
   Z"
 />
 <path fill="currentColor" d="
   M 238.8313 161.3898
   A 11.21 7.79 90.3 0 1 231.1001 150.1392
   A 11.21 7.79 90.3 0 1 238.9487 138.9702
   A 11.21 7.79 90.3 0 1 246.6799 150.2208
   A 11.21 7.79 90.3 0 1 238.8313 161.3898
   Z
   M 238.8758 156.6599
   A 6.53 2.80 90.3 0 0 241.7100 150.1447
   A 6.53 2.80 90.3 0 0 238.9442 143.6001
   A 6.53 2.80 90.3 0 0 236.1100 150.1153
   A 6.53 2.80 90.3 0 0 238.8758 156.6599
   Z"
 />
 <path fill="currentColor" d="
   M 363.0623 161.3896
   A 11.20 7.79 90.5 0 1 355.3703 150.1220
   A 11.20 7.79 90.5 0 1 363.2577 138.9904
   A 11.20 7.79 90.5 0 1 370.9497 150.2580
   A 11.20 7.79 90.5 0 1 363.0623 161.3896
   Z
   M 363.1146 156.6198
   A 6.50 2.81 90.4 0 0 365.9699 150.1396
   A 6.50 2.81 90.4 0 0 363.2054 143.6202
   A 6.50 2.81 90.4 0 0 360.3501 150.1004
   A 6.50 2.81 90.4 0 0 363.1146 156.6198
   Z"
 />
 <path fill="currentColor" d="
   M 516.15 154.16
   C 516.69 155.82 518.56 157.44 520.30 156.14
   Q 521.99 154.88 521.60 156.95
   Q 521.35 158.26 521.67 159.65
   Q 521.78 160.15 521.33 160.38
   C 516.38 162.95 512.32 159.42 511.16 154.67
   C 509.56 148.10 511.72 136.66 521.01 139.59
   A 0.73 0.72 8.6 0 1 521.51 140.26
   L 521.61 143.78
   Q 521.64 145.13 520.56 144.32
   Q 518.92 143.08 517.48 144.34
   C 515.01 146.52 515.30 151.50 516.15 154.16
   Z"
 />
 <path fill="currentColor" d="
   M 222.97 147.22
   L 222.89 140.00
   A 0.49 0.49 0.0 0 1 223.38 139.51
   L 227.47 139.50
   A 0.49 0.49 0.0 0 1 227.96 139.99
   L 227.97 160.39
   A 0.49 0.49 0.0 0 1 227.48 160.88
   L 223.39 160.91
   A 0.49 0.49 0.0 0 1 222.89 160.41
   L 222.97 152.46
   A 0.49 0.49 0.0 0 0 222.49 151.97
   L 219.95 151.90
   A 0.49 0.49 0.0 0 0 219.44 152.40
   L 219.49 160.43
   A 0.49 0.49 0.0 0 1 219.00 160.92
   L 214.94 160.91
   A 0.49 0.49 0.0 0 1 214.45 160.42
   L 214.44 139.99
   A 0.49 0.49 0.0 0 1 214.93 139.50
   L 219.02 139.49
   A 0.49 0.49 0.0 0 1 219.51 139.99
   L 219.39 147.25
   A 0.49 0.49 0.0 0 0 219.89 147.75
   L 222.49 147.72
   A 0.49 0.49 0.0 0 0 222.97 147.22
   Z"
 />
 <path fill="currentColor" d="
   M 254.53 147.83
   Q 254.44 153.35 254.65 160.29
   A 0.64 0.64 0.0 0 1 254.00 160.95
   L 250.45 160.90
   Q 249.77 160.89 249.77 160.20
   L 249.77 140.24
   Q 249.77 139.50 250.51 139.50
   L 254.60 139.49
   Q 255.22 139.49 255.41 140.08
   L 257.53 146.59
   A 0.47 0.47 0.0 0 0 258.42 146.59
   L 260.49 140.13
   Q 260.70 139.49 261.38 139.49
   L 265.47 139.50
   A 0.72 0.72 0.0 0 1 266.19 140.22
   L 266.19 160.24
   A 0.68 0.68 0.0 0 1 265.51 160.92
   L 261.92 160.92
   Q 261.29 160.92 261.29 160.29
   Q 261.26 153.54 261.55 147.63
   Q 261.56 147.31 261.26 147.19
   Q 261.21 147.17 261.16 147.18
   Q 260.96 147.23 260.92 147.42
   Q 259.94 151.51 258.72 155.50
   Q 257.93 158.11 257.20 155.48
   L 255.07 147.76
   Q 254.57 145.93 254.53 147.83
   Z"
 />
 <path fill="currentColor" d="
   M 275.48 156.34
   L 281.54 156.34
   A 0.46 0.46 0.0 0 1 282.00 156.80
   L 281.98 160.44
   A 0.46 0.46 0.0 0 1 281.52 160.90
   L 270.67 160.91
   A 0.46 0.46 0.0 0 1 270.21 160.45
   L 270.21 139.95
   A 0.46 0.46 0.0 0 1 270.67 139.49
   L 281.54 139.50
   A 0.46 0.46 0.0 0 1 282.00 139.96
   L 281.99 143.34
   A 0.46 0.46 0.0 0 1 281.53 143.80
   L 275.53 143.79
   A 0.46 0.46 0.0 0 0 275.07 144.24
   L 274.98 147.09
   A 0.46 0.46 0.0 0 0 275.44 147.56
   L 281.06 147.55
   A 0.46 0.46 0.0 0 1 281.52 148.01
   L 281.49 151.55
   A 0.46 0.46 0.0 0 1 281.02 152.01
   L 275.52 151.90
   A 0.46 0.46 0.0 0 0 275.05 152.36
   L 275.02 155.88
   A 0.46 0.46 0.0 0 0 275.48 156.34
   Z"
 />
 <path fill="currentColor" d="
   M 299.29 160.51
   A 0.40 0.40 0.0 0 1 298.89 160.91
   L 294.71 160.91
   A 0.40 0.40 0.0 0 1 294.31 160.51
   L 294.31 139.91
   A 0.40 0.40 0.0 0 1 294.71 139.51
   L 298.89 139.51
   A 0.40 0.40 0.0 0 1 299.29 139.91
   L 299.29 160.51
   Z"
 />
 <path fill="currentColor" d="
   M 307.93 148.04
   Q 308.44 154.13 308.21 160.50
   A 0.43 0.43 0.0 0 1 307.78 160.92
   L 303.87 160.90
   A 0.42 0.41 -0.0 0 1 303.45 160.49
   L 303.45 140.27
   Q 303.45 139.50 304.23 139.50
   L 308.14 139.49
   Q 308.82 139.49 309.04 140.14
   L 311.10 146.19
   Q 311.60 147.63 312.08 146.19
   L 313.97 140.55
   Q 314.33 139.48 315.45 139.49
   L 319.01 139.50
   Q 319.77 139.50 319.77 140.26
   L 319.75 160.46
   Q 319.75 160.87 319.34 160.88
   L 315.42 160.94
   Q 314.89 160.95 314.90 160.42
   L 315.15 148.22
   Q 315.18 146.55 314.62 148.12
   Q 313.28 151.89 312.36 155.48
   Q 311.66 158.24 310.93 155.49
   Q 309.90 151.66 308.48 147.92
   Q 307.76 146.05 307.93 148.04
   Z"
 />
 <path fill="currentColor" d="
   M 328.47 153.19
   L 328.52 160.19
   Q 328.53 160.91 327.81 160.91
   L 324.25 160.93
   Q 323.57 160.93 323.57 160.25
   L 323.56 140.20
   Q 323.56 139.51 324.25 139.50
   Q 328.33 139.45 332.09 139.54
   C 339.13 139.70 339.07 153.35 329.36 152.38
   Q 328.46 152.29 328.47 153.19
   Z
   M 328.48 143.93
   L 328.50 147.89
   A 0.28 0.28 0.0 0 0 328.78 148.17
   L 329.29 148.17
   A 2.81 2.03 -0.3 0 0 332.09 146.12
   L 332.09 145.66
   A 2.81 2.03 -0.3 0 0 329.27 143.65
   L 328.76 143.65
   A 0.28 0.28 0.0 0 0 328.48 143.93
   Z"
 />
 <path fill="currentColor" d="
   M 349.46 150.60
   Q 350.52 151.17 351.28 152.14
   A 2.74 2.73 20.2 0 1 351.80 153.26
   L 353.33 160.66
   Q 353.39 160.97 353.08 160.96
   L 348.30 160.87
   A 0.36 0.35 87.1 0 1 347.96 160.56
   L 347.11 153.68
   Q 346.97 152.60 345.94 152.30
   L 345.51 152.17
   A 0.60 0.60 0.0 0 0 344.74 152.75
   L 344.81 160.26
   A 0.63 0.63 0.0 0 1 344.18 160.90
   L 340.49 160.92
   A 0.68 0.68 0.0 0 1 339.80 160.24
   L 339.79 140.04
   Q 339.79 139.51 340.32 139.50
   Q 344.24 139.44 348.07 139.54
   C 354.08 139.70 354.61 147.82 349.51 149.82
   Q 348.64 150.16 349.46 150.60
   Z
   M 344.77 144.09
   L 344.75 147.77
   A 0.46 0.46 0.0 0 0 345.21 148.24
   L 345.56 148.24
   A 2.47 2.07 0.2 0 0 348.04 146.18
   L 348.04 145.72
   A 2.47 2.07 0.2 0 0 345.58 143.64
   L 345.23 143.64
   A 0.46 0.46 0.0 0 0 344.77 144.09
   Z"
 />
 <path fill="currentColor" d="
   M 379.85 151.06
   Q 380.19 153.37 380.62 151.07
   Q 381.61 145.87 382.40 140.17
   A 0.79 0.79 0.0 0 1 383.18 139.50
   L 387.31 139.49
   A 0.45 0.45 0.0 0 1 387.75 140.05
   L 382.87 160.08
   Q 382.67 160.90 381.83 160.91
   L 378.66 160.93
   Q 377.87 160.94 377.69 160.16
   L 372.87 139.74
   Q 372.81 139.51 373.05 139.51
   L 377.81 139.49
   Q 378.15 139.48 378.20 139.82
   L 379.85 151.06
   Z"
 />
 <path fill="currentColor" d="
   M 396.21 151.94
   Q 395.59 151.93 395.58 152.54
   L 395.52 155.98
   Q 395.52 156.44 395.98 156.44
   L 402.01 156.38
   Q 402.47 156.37 402.48 156.83
   L 402.50 160.20
   Q 402.51 160.90 401.80 160.90
   L 391.05 160.92
   A 0.39 0.39 0.0 0 1 390.66 160.53
   L 390.66 139.85
   A 0.35 0.35 0.0 0 1 391.01 139.50
   L 401.99 139.49
   Q 402.50 139.49 402.50 140.00
   Q 402.48 143.33 402.48 143.34
   Q 402.48 143.83 402.00 143.82
   Q 399.01 143.82 396.23 143.77
   A 0.67 0.66 -88.7 0 0 395.56 144.42
   L 395.53 146.95
   A 0.68 0.67 0.8 0 0 396.21 147.64
   L 401.45 147.62
   Q 402.03 147.62 402.03 148.20
   L 402.05 151.10
   Q 402.05 152.00 401.15 151.99
   L 396.21 151.94
   Z"
 />
 <path fill="currentColor" d="
   M 417.61 149.19
   Q 417.66 145.62 416.69 149.05
   L 414.92 155.28
   Q 414.12 158.10 413.30 155.28
   Q 412.32 151.87 411.00 147.24
   Q 410.94 147.05 410.75 147.06
   Q 410.56 147.08 410.56 147.27
   L 410.80 160.20
   Q 410.81 160.95 410.05 160.94
   L 406.53 160.88
   A 0.61 0.60 0.5 0 1 405.93 160.28
   L 405.93 140.28
   Q 405.93 139.50 406.72 139.49
   L 410.70 139.49
   Q 411.41 139.49 411.63 140.17
   L 413.69 146.59
   A 0.41 0.41 0.0 0 0 414.35 146.78
   Q 414.73 146.44 414.86 145.92
   Q 415.66 142.66 416.77 139.98
   Q 416.97 139.49 417.51 139.49
   L 421.77 139.49
   Q 422.29 139.49 422.29 140.02
   L 422.29 160.44
   A 0.48 0.48 0.0 0 1 421.81 160.92
   L 417.94 160.93
   Q 417.44 160.93 417.45 160.43
   L 417.61 149.19
   Z"
 />
 <path fill="currentColor" d="
   M 431.28 144.22
   L 431.12 147.07
   A 0.46 0.46 0.0 0 0 431.57 147.55
   L 437.27 147.58
   A 0.46 0.46 0.0 0 1 437.73 148.05
   L 437.66 151.56
   A 0.46 0.46 0.0 0 1 437.19 152.01
   L 431.72 151.89
   A 0.46 0.46 0.0 0 0 431.25 152.34
   L 431.20 155.88
   A 0.46 0.46 0.0 0 0 431.66 156.35
   L 437.67 156.31
   A 0.46 0.46 0.0 0 1 438.14 156.78
   L 438.11 160.44
   A 0.46 0.46 0.0 0 1 437.65 160.90
   L 426.80 160.92
   A 0.46 0.46 0.0 0 1 426.34 160.46
   L 426.34 139.95
   A 0.46 0.46 0.0 0 1 426.80 139.49
   L 437.67 139.49
   A 0.46 0.46 0.0 0 1 438.13 139.95
   L 438.14 143.37
   A 0.46 0.46 0.0 0 1 437.68 143.83
   L 431.74 143.78
   A 0.46 0.46 0.0 0 0 431.28 144.22
   Z"
 />
 <path fill="currentColor" d="
   M 450.44 139.50
   L 455.09 139.49
   A 0.15 0.15 0.0 0 1 455.24 139.64
   L 455.24 160.77
   A 0.15 0.15 0.0 0 1 455.09 160.92
   L 450.32 160.92
   A 0.15 0.15 0.0 0 1 450.18 160.82
   L 446.35 149.39
   A 0.15 0.15 0.0 0 0 446.18 149.29
   L 445.92 149.35
   A 0.15 0.15 0.0 0 0 445.80 149.50
   L 446.21 160.76
   A 0.15 0.15 0.0 0 1 446.06 160.92
   L 441.65 160.92
   A 0.15 0.15 0.0 0 1 441.50 160.77
   L 441.50 139.64
   A 0.15 0.15 0.0 0 1 441.65 139.49
   L 446.49 139.49
   A 0.15 0.15 0.0 0 1 446.63 139.59
   L 450.17 150.69
   A 0.15 0.15 0.0 0 0 450.46 150.64
   L 450.29 139.65
   A 0.15 0.15 0.0 0 1 450.44 139.50
   Z"
 />
 <path fill="currentColor" d="
   M 458.36 143.52
   L 458.27 140.07
   A 0.56 0.56 0.0 0 1 458.82 139.50
   L 470.99 139.49
   A 0.56 0.56 0.0 0 1 471.55 140.06
   L 471.50 143.57
   A 0.56 0.56 0.0 0 1 470.89 144.12
   L 467.96 143.87
   A 0.56 0.56 0.0 0 0 467.35 144.43
   L 467.38 160.35
   A 0.56 0.56 0.0 0 1 466.82 160.91
   L 462.98 160.90
   A 0.56 0.56 0.0 0 1 462.42 160.34
   L 462.46 144.51
   A 0.56 0.56 0.0 0 0 461.88 143.95
   L 458.94 144.07
   A 0.56 0.56 0.0 0 1 458.36 143.52
   Z"
 />
 <path fill="currentColor" d="
   M 488.98 156.34
   L 494.79 156.30
   A 0.42 0.42 0.0 0 1 495.21 156.72
   L 495.22 160.48
   A 0.42 0.42 0.0 0 1 494.80 160.90
   L 484.05 160.91
   A 0.42 0.42 0.0 0 1 483.63 160.49
   L 483.63 139.91
   A 0.42 0.42 0.0 0 1 484.05 139.49
   L 488.21 139.50
   A 0.42 0.42 0.0 0 1 488.63 139.92
   L 488.56 155.92
   A 0.42 0.42 0.0 0 0 488.98 156.34
   Z"
 />
 <path fill="currentColor" d="
   M 502.74 156.32
   L 508.54 156.35
   A 0.42 0.42 0.0 0 1 508.96 156.78
   L 508.89 160.48
   A 0.42 0.42 0.0 0 1 508.47 160.89
   L 497.80 160.92
   A 0.42 0.42 0.0 0 1 497.38 160.50
   L 497.38 139.93
   A 0.42 0.42 0.0 0 1 497.80 139.51
   L 501.96 139.48
   A 0.42 0.42 0.0 0 1 502.38 139.90
   L 502.32 155.90
   A 0.42 0.42 0.0 0 0 502.74 156.32
   Z"
 />
 </g>
 </svg>
 

);

export default Logo;

